import React from "react";
import {
	MDBCardTitle,
	MDBCardBody,
	MDBCard,
	MDBBtn,
	MDBIcon,
	MDBCol,
	MDBRow,
	MDBBadge
} from "mdbreact";
import { Link } from "gatsby";
import { FormatDate } from "../../helpers/formatters";

export default function CardAviso({ tipo, name, slug, ultimaModificacao, redirecionamento }) {
	return (
		<MDBCard className="h-100">
			<MDBCardBody className="pb-0 h-100">
				{tipo &&
					tipo?.map((i) => (
						<MDBBadge pill color="2EC034" className="mr-1">{i}</MDBBadge>
					))
				}
				<MDBCardTitle className="mt-2 mb-3">
					{/* {redirecionamento == null ? */}
						<Link to={slug}>
							{name}
						</Link>
					{/* :
						<a href={redirecionamento}>
							{name}
						</a>
					} */}
				</MDBCardTitle>
			</MDBCardBody>
			<hr className="p-0 m-0" />
			<div className="list-inline px-3">
					<MDBRow className="align-middle">
						<MDBCol className="float-left my-auto text-mute small" style={{ color: "#6d6e72" }}>
							<MDBIcon far icon="clock" />{" "}
							{FormatDate(ultimaModificacao)}
						</MDBCol>
						<MDBCol className="text-right no-gutters my-1">
							{/* {redirecionamento == null ? */}
								<Link to={slug}>
									<MDBBtn className="btn-yellow px-5 mx-0 ">
										<MDBIcon
											icon="chevron-circle-right"
											aria-hidden="true"
										/>{" "}
										Acesse
									</MDBBtn>
								</Link>
							{/* :
								<a href={redirecionamento} target="_blank">
									<MDBBtn className="btn-yellow px-5 mx-0 ">
										<MDBIcon
											icon="chevron-circle-right"
											aria-hidden="true"
										/>{" "}
										Acesse
									</MDBBtn>
								</a>
							} */}
							
						</MDBCol>
					</MDBRow>
			</div>
		</MDBCard>
	);
}
