import * as React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/MasterLayout/MasterLayout";
import {
	MDBContainer,
	MDBRow,
	MDBCol,
} from "mdbreact";
import BannerCarousel from "../components/BannerCarousel/BannerCarousel";
import SEO from "../components/SEO/SEO";
import FiltroAvisos from "../components/FiltroAvisos/FiltroAvisos";

export default function IndexPage({ pageContext: { avisos } }) {
	IndexPage.propTypes = {
		pageContext: PropTypes.object.isRequired,
		avisos: PropTypes.array.isRequired, // Array de avisos
	};

	return (
		<Layout>
			<SEO title="Todos os avisos" />
			<MDBContainer fluid>
				<MDBRow>
					<MDBCol style={{ paddingLeft: 0, paddingRight: 0 }}>
						<BannerCarousel bannerKey="8F600990-F9D5-4429-BFA3-3E3D99B161AE" />
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<MDBContainer>
				<MDBRow>
					<MDBCol className="text-center text-md-left mt-4">
						<h1 className="text-green">Todos os avisos</h1>
						<p>Confira todos os avisos na Universidade Feevale.</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<FiltroAvisos avisos={avisos} />
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
